/* You can add global styles to this file, and also import other style files */
// @import 'breakpoints';
 @import 'fonts';
 
 @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
 
body {
    font-family: Roboto;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    overflow-y: scroll;
    background: var(--dualog-white);
    -webkit-font-smoothing: antialiased;
    //TODO change to some dualog color
    background-color: #EBF0F2; 
  }