@font-face {
      font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        src: local(''), url('./Poppins/poppins-300.woff2') format('woff2'),
          url('./Poppins/poppins-300.woff') format('woff'),
          url('./Poppins/poppins-300.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('./Poppins/poppins-400.woff2') format('woff2'),
      url('./Poppins/poppins-400.woff') format('woff'),
      url('./Poppins/poppins-400.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('./Poppins/poppins-500.woff2') format('woff2'),
      url('./Poppins/poppins-500.woff') format('woff'),
      url('./Poppins/poppins-500.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('./Poppins/poppins-600.woff2') format('woff2'),
      url('./Poppins/poppins-600.woff') format('woff'),
      url('./Poppins/poppins-600.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('./Roboto/roboto-300.woff2') format('woff2'),
      url('./Roboto/roboto-300.woff') format('woff'),
      url('./Roboto/roboto-300.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('./Roboto/roboto-400.woff2') format('woff2'),
      url('./Roboto/roboto-400.woff') format('woff'),
      url('./Roboto/roboto-400.ttf') format('truetype');
  }

  @font-face {
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('./Roboto/roboto-500.woff2') format('woff2'),
      url('./Roboto/roboto-500.woff') format('woff'),
      url('./Roboto/roboto-500.ttf') format('truetype');
  }

  @font-face {
  font-family: 'text-security-disc';
    src: url('./TextSecurityDisc/text-security-disc-compat.eot');
    src: url('./TextSecurityDisc/text-security-disc-compat.eot?#iefix') format('embedded-opentype'),
      url('./TextSecurityDisc/text-security-disc-compat.woff2') format('woff2'),
      url('./TextSecurityDisc/text-security-disc-compat.ttf') format('truetype');
  }
